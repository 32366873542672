















































































import {Component, Prop, Vue} from "vue-property-decorator";
import {OutputFmonDaemonRegistrationRequestDTO, OutputLocationDTO} from "@/api";
import Loading from "@/components/Loading.vue";
import {formatApiTimeString} from "@/utils";
import FmonDaemonRegistrationRequestStatus, {
  formatRegistrationRequestStatus
} from "@/types/FmonDaemonRegistrationRequestStatus";

@Component({
  components: {Loading}
})
export default class RegistrationRequestView extends Vue {
  @Prop(Number) readonly requestId;

  public request: OutputFmonDaemonRegistrationRequestDTO|null = null;
  public locations: OutputLocationDTO[] = [];
  public approveLocationId: number | null = null;

  public async mounted() {
    this.request = await this.$store.dispatch('fmonDaemonRegistrationRequests/get', { requestId: this.requestId });
    this.locations = await this.$store.dispatch('locations/getAll');
  }

  public async clear() {
    await this.$store.dispatch('fmonDaemonRegistrationRequests/clear', { requestId: this.requestId });
    await this.$router.push('/main/maintenance/fmon-daemon-registration-requests/all');
  }

  public async reject() {
    await this.$store.dispatch('fmonDaemonRegistrationRequests/reject', { requestId: this.requestId });
    await this.$router.push('/main/maintenance/fmon-daemon-registration-requests/all');
  }

  public async approve() {
    if (this.approveLocationId === null) {
      return;
    }

    await this.$store.dispatch('fmonDaemonRegistrationRequests/approve', { requestId: this.requestId, locationId: this.approveLocationId });
    await this.$router.push('/main/maintenance/fmon-daemon-registration-requests/all');
  }

  get formattedCreatedAt() {
    if (this.request) {
      return formatApiTimeString(this.request.created_at);
    } else {
      return "";
    }
  }

  get formattedCleared() {
    if (this.request?.cleared) {
      return "Yes";
    } else {
      return "No"
    }
  }

  get formattedStatus() {
    if (this.request) {
      return formatRegistrationRequestStatus(this.request.status!);
    } else {
      return "";
    }
  }

  get locationItems() {
    return this.locations.map(location => {
      return {
        text: location.name,
        value: location.id
      }
    })
  }

  get canApprove() {
    return this.request !== null && !this.request.cleared && (
        this.request.status === FmonDaemonRegistrationRequestStatus.PENDING_USER
        || this.request.status === FmonDaemonRegistrationRequestStatus.REJECTED_USER
    )
  }

  get canReject() {
    return this.request !== null && !this.request.cleared && this.request.status === FmonDaemonRegistrationRequestStatus.PENDING_USER
  }

  get canClear() {
    return this.request !== null && !this.request.cleared && this.request.status !== FmonDaemonRegistrationRequestStatus.COMPLETED
  }
}
